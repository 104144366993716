@import "../../../../node_modules/bootstrap/scss/_mixins";
@import "../../../../node_modules/bootstrap/scss/bootstrap-grid";

// Variables and Mixins for use throughout development
@import "../../utils/variables";

.menu-inicio {
    color: $yellow-1 !important;
}
.homepage {
    &-carousel {
        width: 100%;
        height: 770px;
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: auto;
        }
        &-container {
            position: relative;

            img {
                width: 100%;
                height: 770px;
                object-fit: cover;
                object-position: center center;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .slick {
            &-arrow {
                width: 24px;
                height: 24px;
                font-size: 24px;
                color: white;
                position: absolute;
                z-index: 2;
                cursor: pointer;
                i {
                    border-radius: 30px;
                    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);
                }
            }

            &-dots {
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 100%;
                bottom: 20px;

                @include media-breakpoint-down(lg) {
                    bottom: 0px;
                }

                button {
                    background-color: $white;
                    width: 8px;
                    border-radius: 30px;
                    height: 8px;
                    padding: 0 !important;
                    transition: all 0.3s ease-in-out;
                    margin: 0px 3px;
                }

            }
            &-active {
                button {
                    background-color: #fc1a1a !important;
                    color: #fc1a1a !important;
                }
            }

            &-back {
                left: 20px;
                top: 60%;
                transform: translateY(-60%);
                @include media-breakpoint-down(lg) {
                    top: 70%;
                    transform: translateY(-70%);
                }
            }
            &-next {
                right: 20px;
                top: 60%;
                transform: translateY(-60%);
                @include media-breakpoint-down(lg) {
                    top: 70%;
                    transform: translateY(-70%);
                }
            }

        }

        &-title {
            position: absolute;
            width: 100%;
            height: fit-content;
            top: 0;
            left: 0;
            padding: 50px 100px;

            @include media-breakpoint-down(lg) {   
                padding: 20px 60px;
            }

            @include media-breakpoint-down(md) {   
                padding: 20px 50px;
            }
        }

        &-headline {
            font-size: 48px;
            line-height: 2;
            font-weight: 700;
            padding-left: 30px;
            color: white;
            text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(sm) {
                font-size: 20px;
                padding-left: 10px;
                margin-bottom: 0px;
            }
        }

        &-subtitle {
            font-size: 148px;
            font-weight: 700;
            color: white;
            text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.50);
            @include media-breakpoint-down(sm) {
                width: 100%;
                font-size: 48px;
                margin-bottom: 0px;
            }
        }

        &-label {
            position: absolute;
            bottom: 5px;
            padding: 0px 30px;
            color: white;
            @include media-breakpoint-down(lg) {
                bottom: 50px;
                padding: 0px 60px;
            }
            @include media-breakpoint-down(md) {
                bottom: 30px;
                padding: 0px 50px;
            }
        }
        
        &-slide-container {
            position: relative;
            @include media-breakpoint-down(lg) {
                margin-bottom: -10px;
            }
        }
    }

}