// Bootstrap breakpoints
$grid-breakpoints: (
  xs: 576px,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Colors
$white: #ffffff;
$dark-gray-1: #565656;
$dark-gray-2: #363636;
$light-gray-1: #dddddd;
$light-gray-2: #bbbbbb;
$light-gray-3: #f8f8f8;
$light-gray-4: #ededed;
$orange: #fa830f;
$blue-1: #002e61;
$blue-2: #003148;
$cream: #fff1db;
$red: #ff2c2c;
$mint: #d1f4e1;
$light-blue: #c0e5fe;
$light-red: #ffd3d4;
$blue: #6FCBD4;
$red: #f54c4e;
$white: #FFF;
$light-blue: #c0e5fe;

$gray-1: #252728;
$yellow-1: #fdda00;
$yellow-2: #ECB22C;

// Fonts
$font-heading: "MadeTommy", sans-serif;
$font-body: "MadeTommy", sans-serif;

$input-bg: $light-gray-3;
$input-text: $blue-1;
$input-placeholder: rgba($blue-1, 0.6);
$input-border: $light-gray-4;